<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row class="ma-0 mb-5">
        <div class="ma-4">
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">Facturas pendientes</div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">
              Aqui encontraras tus facturas que aun no se ha bloqueado el tipo de cambio y que estan pendientes de asignarse.
            </div>
          </v-col>
        </div>
        <v-spacer></v-spacer>
        <v-card class="card-main" elevation="4">
          <div class="card-content">
            <div class="card-title title-medium">Tipo de cambio actual</div>
            <div class="card-mxn h5" v-if="items">
              {{ formatMoney(items.exchangeRateToday ? items.exchangeRateToday : 0) }} MXN
            </div>
            <div class="card-info">
              Tipo de cambio se actualizará en: {{ formattedTime }}
            </div>
          </div>
          <v-sheet
            class="circle-sheet"
            color="#F0BE43"
            style="border-radius: 155px"
          >
            <div class="circle-content"></div>
          </v-sheet>
        </v-card>
      </v-row>
      <v-card class="rounded-xl elevation-5">
        <v-toolbar dense dark color="#1d2b3c" elevation="2">
          <v-toolbar-title>Facturas pendientes de asignar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn elevation="0" text small >
            <!-- <xlsx-workbook>
              <xlsx-sheet :collection="sheet.data" v-for="sheet in sheetsGeneral" :key="sheet.name" :sheet-name="sheet.name" />
              <xlsx-download :filename="'Reporte ' + this.reportTypeGeneral + '.xlsx'">
                <button>DESCARGAR</button>
                <v-icon right>mdi-microsoft-excel</v-icon>
              </xlsx-download>
            </xlsx-workbook> -->
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            style="
              text-align: center;
              color: #f0be43;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #f0be43;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            @click="openDialogSupplier()"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            Agregar factura
          </v-btn>
          <v-btn
            style="
              text-align: center;
              color: #f0be43;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #f0be43;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            :disabled="selected.length == 0"
            @click="openPayNow(selected)"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            Pagar
          </v-btn>
          <v-btn
            style="
              text-align: center;
              color: #f0be43;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #f0be43;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            :disabled="selected.length == 0"
            @click="openConvert(selected)"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            Convertir
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table show-select single-select item-key="sInvoiceId" v-model="selected" :headers="headersInvoices" :items="invoices" :disable-sort="true">
            <template v-slot:item.sName="{ item }">
              <h4 style="font-family: 'Montserrat', sans-serif;"> {{ item.sName  }} </h4>
            </template>
            <template v-slot:item.dDueDate="{ item }">
              <h4 style="font-family: 'Montserrat', sans-serif;"> {{ formatDate(item.dDueDate) }} </h4>
            </template>
            <template v-slot:item.dPurchaseDate="{ item }">
              <h4 style="font-family: 'Montserrat', sans-serif;"> {{ formatDate(item.dPurchaseDate) }} </h4>
            </template>
            <template v-slot:item.sInvoice="{ item }">
              <h4 style="font-family: 'Montserrat', sans-serif;"> {{ item.sInvoice }} </h4>
            </template>
            <template v-slot:item.dTotalAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dTotalAmount) }} MXN </h4>
            </template>
            <template v-slot:item.dRateProtection="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dRateProtection) }} MXN </h4>
            </template>
            <template v-slot:item.dProfitPercentage="{ item }">
              <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dProfitPercentage + "%" }} </h4>
            </template>
            <template v-slot:item.dCurrencyGain="{ item }">
              <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dCurrencyGain) }} USD </h4>
            </template>
            <template v-slot:item.sStatusId="{ item }">
              <h4 > {{ item.sStatusId == "453f47d8-d5ad-4159-be49-b48b0f04caa5" ? "To pay" : "" }} </h4>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="display: flex; align-items: center; justify-content: center;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="editInvoice(item)" :disabled="loading" v-bind="attrs" v-on="on" icon color="black">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar factura</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openDialogDeleteInvoice(item)" :disabled="loading" v-bind="attrs" v-on="on" icon color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete item</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-container class="mt-1">
          <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Detalle de pago
            </span>
          </v-card-title>
          <v-card-text class="py-6 pb-0" v-if="!loading">
            <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
              <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Nombre del cliente:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ payDetail.supplier }}
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Número CLABE:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{  "********" + payDetail.sTaxId }}
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a enviar:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ payDetail.dTotalUsd }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  El destinatario recibirá:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ payDetail.dTotalAmount }} MXN
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Tipo de cambio:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ formatMoney(payDetail.exchangeRateToday) }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Factura a pagar:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ payDetail.sInvoice }}
                </div>
              </div>
              <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Fecha de envío:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ today }}
                </div>
              </div>
            </div>
          </v-card-text>
          <div v-else style="display: flex; justify-content: center;">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                @click="dialogSendPay = false"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              @click="payInvoice()"
              >Confirmar pago
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogForex" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-container class="mt-1">
          <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Detalle de la conversión
            </span>
          </v-card-title>
          <v-card-text class="py-6 pb-0" v-if="!loading">
            <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a enviar:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ forexDetail.totalUSD }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a recibir en tu cuenta:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ forexDetail.dTotalAmount }} MXN
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Tipo de cambio:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ formatMoney(payDetail.exchangeRateToday) }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Factura a convertir:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ forexDetail.sInvoice }}
                </div>
              </div>
            </div>
          </v-card-text>
          <div v-else style="display: flex; justify-content: center;">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                :disabled="loading"
                @click="dialogForex = false"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              :disabled="loading"
              @click="convertInvoice()"
              >Confirmar forex
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogForward" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-container class="mt-1">
          <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Detalle de la conversión
            </span>
          </v-card-title>
          <v-card-text class="py-6 pb-0" v-if="!loading">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dDateForward"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dDateForward"
                    label="Selecciona la fecha de tu forward"
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    class="mb-3"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dDateForward"
                  :min="today"
                  :max="limit"
                  @change="getForwardRate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    :disabled="loadingExchange"
                    @click="$refs.dialog.save(dDateForward)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
            </v-dialog>
            <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
              
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a enviar:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ forwardDetail.totalUSD }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a recibir en tu cuenta:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ forwardDetail.dTotalAmount }} MXN
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Tipo de cambio:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ formatMoney(forwardActualRate) }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Factura a convertir:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ forwardDetail.sInvoice }}
                </div>
              </div>
            </div>
          </v-card-text>
          <div v-else style="display: flex; justify-content: center;">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                :disabled="loading"
                @click="cancel()"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              :disabled="loading"
              @click="sendForward()"
              >Confirmar forward
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConvert" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-container class="mt-1">
          <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Detalle de pago
            </span>
          </v-card-title>
          <v-card-text class="py-6 pb-0" v-if="!loading">
            <v-col cols="12">
              <v-card
                class="d-flex pa-6"
                outlined
                :class="{ 'selected-card': selectedCard === 2 }"
                @click="selectCard(2)"
                elevation="0"
                style="
                  align-self: stretch;
                  background: white;
                  border-radius: 10px;
                  border: 1px solid #dddddd;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 16px;
                "
              >
                <div
                  class="d-flex flex-column"
                  style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                >
                  <div
                    class="subtitle-1 font-weight-bold"
                    style="color: #302e2e; font-size: 24px"
                  >
                    {{ texts.payments.paySeparate }}
                  </div>
                  <div
                    class="body-1"
                    style="
                      color: #666565;
                      text-align: justify;
                    "
                  >
                    {{ texts.payments.paySeparateDescription }}
                  </div>
                </div>
                <v-icon v-if="selectedCard === 2" color="#f0be43"
                  >mdi-check-circle
                </v-icon>
                <v-icon v-else color="grey lighten-1"
                  >mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                class="d-flex pa-6"
                outlined
                :class="{ 'selected-card': selectedCard === 3 }"
                @click="selectCard(3)"
                elevation="0"
                style="
                  align-self: stretch;
                  background: white;
                  border-radius: 10px;
                  border: 1px solid #dddddd;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 16px;
                "
              >
                <div
                  class="d-flex flex-column"
                  style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                >
                  <div
                    class="subtitle-1 font-weight-bold"
                    style="color: #302e2e; font-size: 24px"
                  >
                    {{ texts.payments.payForward }}
                  </div>
                  <div
                    class="body-1"
                    style="
                      color: #666565;
                      text-align: justify;
                    "
                  >
                    {{ texts.payments.payForwardDescription }}
                  </div>
                </div>
                <v-icon v-if="selectedCard === 3" color="#f0be43"
                  >mdi-check-circle
                </v-icon>
                <v-icon v-else color="grey lighten-1"
                  >mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-card>
            </v-col>
          </v-card-text>
          <div v-else style="display: flex; justify-content: center;">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                @click="dialogConvert = false"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              @click="selectTypeConversion(selectedCard)"
              >Continuar
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center;">
          <span class="text-h5">Agregar factura</span>
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px;" >Ingresa la siguiente información para agregar una nueva factura.</span>
        </v-card-subtitle>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form ref="formNew" style="width: 100%;" lazy-validation v-on:submit.prevent>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre de la invoice*"
                    required
                    v-model="forms.new.sInvoice"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Nombre del proveedor*"
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="forms.new.sPaymentRecipientId"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Fecha de compra*"
                    required
                    type="date"
                    v-model="forms.new.dPurchaseDate"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Total en pesos MXN*"
                    required
                    type="number"
                    v-model="forms.new.dTotalAmount"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tiempo del credito*"
                    required
                    type="number"
                    v-model="forms.new.iCreditPeriod"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tipo de cambio protegido*"
                    required
                    type="number"
                    v-model="forms.new.dRateProtection"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="saveInvoice"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center;">
          <span class="text-h5">Agregar factura</span>
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px;" >Ingresa la siguiente información para agregar una nueva factura.</span>
        </v-card-subtitle>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form ref="formEdit" style="width: 100%;" lazy-validation v-on:submit.prevent>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre de la invoice*"
                    required
                    v-model="forms.detail.sInvoice"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Nombre del proveedor*"
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="forms.detail.sPaymentRecipientId"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Fecha de compra*"
                    required
                    type="date"
                    v-model="forms.detail.dPurchaseDate"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Total en pesos MXN*"
                    required
                    type="number"
                    v-model="forms.detail.dTotalAmount"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tiempo del credito*"
                    required
                    type="number"
                    v-model="forms.detail.iCreditPeriod"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tipo de cambio protegido*"
                    required
                    type="number"
                    v-model="forms.detail.dRateProtection"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="dialogEditInvoice = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="updateInvoice()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="fit-content">
      <v-card>
        <v-card-title class="text-h5 my-4">
          ¿Estás seguro de que deseas eliminar la factura?
        </v-card-title>
        <v-card-text v-if="!loading" class="my-6">Una vez que eliminas la factura no podras recuperarla.</v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="cancel()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="deleteInvoice()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      modal: false,
      dialogForward: false,
      loadingExchange: false,
      texts: "",
      selectedCard: 0,
      dialog: false,
      dialogEditInvoice: false,
      dialogDelete: false,
      dialogForex: false,
      sExchangeRateReference: null,
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      dialogConvert: false,
      isLoading: false,
      isRegisterEfex: true,
      invoices: [],
      exchangeRateData: [],
      selected: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      limit: null,
      tab: 0,
      contacts: [],
      today: null,
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      loading: false,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      dDateForward: null,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      forms: {
        new: {
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        detail: {
          sInvoiceId: "",
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        delete: {
          sInvoiceId: "",
        }
      },
      stepper: false,
      e1: 1,
      suppliers: [],
      timerCount: 30,
      headersInvoices: [
        { text: "Supplier", value: "sName", align: "center" },
        { text: "Purchase date", value: "dPurchaseDate", align: "center" },
        { text: "Invoice", value: "sInvoice", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Exchange rate protection MXN", value: "dRateProtection", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Status", value: "sStatusId", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForwards: [
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Transaction date", value: "dTransactionDate", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Missing days", value: "diasFaltantes", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate", value: "dRateProtection", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForex: [
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate", value: "dRateProtection", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      itemsForwards: [
        { type: "Forward", dTransactionDate: "March 15, 2024", diasFaltantes: "-7 days", dDueDate: "April 15, 2024", dTotalAmount: "300000", dRateProtection: "16.50", invoice: "", purchaseDate: "", dProfitPercentage: "", dCurrencyGain: "" },
        { type: "Forward", dTransactionDate: "April 1, 2024", diasFaltantes: "8 days", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-102", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "400"}
      ],
      itemsForex: [
        { type: "Forex", diasFaltantes: "-3", dDueDate: "April 15, 2024", dTotalAmount: "300000", dRateProtection: "16.50", invoice: "", purchaseDate: "", dProfitPercentage: "", dCurrencyGain: "" },
        { type: "Forex", diasFaltantes: "13", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-103", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "3000"},
        { type: "Forward", diasFaltantes: "13", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-104", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "200"}
      ],
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      forwardRate: null,
      modalCurrency: 0,
      forwardActualRate: null,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false
            },
          ]
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true
            }
          ]
        }
      ],
      payDetail: {
        supplier: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
        sTaxId: "",
        exchangeRateToday: "",
        sInvoice: "",
      },
      forexDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: ""
      },
      forwardDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: ""
      },
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" }
      ],
      transactions: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    },
    openDialogSupplier() {
      this.dialog = true;
    },
    openConvert(item) {
      this.dialogConvert = true;
    },
    openPayNow(item) {
      this.dialogSendPay = true;
      this.payDetail.sInvoiceId = item[0].sInvoiceId;
      this.payDetail.supplier = item[0].sName;
      this.payDetail.dTotalUsd = item[0].dTotalUsd;
      this.payDetail.dTotalAmount = item[0].dTotalAmount;
      this.payDetail.sTaxId = item[0].sTaxId.slice(-4);
      this.payDetail.sInvoice = item[0].sInvoice;
    },
    selectCard(cardNumber) {
      this.selectedCard = cardNumber;
    },
    selectTypeConversion(cardNumber) {
      this.dialogConvert = false;
      if (cardNumber == 2) {
        this.dialogForex = true;
        this.forexDetail.sInvoiceId = this.selected[0].sInvoiceId;
        this.forexDetail.sInvoice = this.selected[0].sInvoice;
        this.forexDetail.totalUSD = this.selected[0].dTotalUsd;
        this.forexDetail.exchangeRate = this.payDetail.exchangeRateToday;
        this.forexDetail.dTotalAmount = this.selected[0].dTotalAmount;
      }
      if (cardNumber == 3) {
        this.dialogForward = true;
        this.forwardDetail.sInvoiceId = this.selected[0].sInvoiceId;
        this.forwardDetail.sInvoice = this.selected[0].sInvoice;
        this.forwardDetail.totalUSD = this.selected[0].dTotalUsd;
        this.forwardDetail.exchangeRate = this.payDetail.exchangeRateToday;
        this.forwardDetail.dTotalAmount = this.selected[0].dTotalAmount;
      }
    },
    getExchangeRate() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.payDetail.exchangeRateToday = response.data.exchangeRateToday
        this.sExchangeRateReference = response.data.exchangeRateTodayReference;
      });
    },
    saveInvoice() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;

        let payload = {
            dPurchaseDate: this.forms.new.dPurchaseDate,
            sPaymentRecipientId: this.forms.new.sPaymentRecipientId,
            sInvoice: this.forms.new.sInvoice,
            dTotalAmount: this.forms.new.dTotalAmount,
            dRateProtection: this.forms.new.dRateProtection,
            iCreditPeriod: this.forms.new.iCreditPeriod,
            contactData: { 
              create: false
            }
        };

        DB.post(
            `${URI}/api/v1/${this.selectLanguage}/invoices/`,
            payload,
            {
              headers: {
                Authorization: this.$store.state.sToken
                  ? `Bearer ${this.$store.state.sToken}`
                  : ""
              }
            }
          )
        .then((response) => {
          this.loading = false;
          this.getInvoices();
          this.cancel();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    updateInvoice() {
      if (this.$refs.formEdit.validate()) {
        this.loading = true;

        let payload = {
            sInvoiceId: this.forms.detail.sInvoiceId,
            dPurchaseDate: this.forms.detail.dPurchaseDate,
            sPaymentRecipientId: this.forms.detail.sPaymentRecipientId,
            sInvoice: this.forms.detail.sInvoice,
            dTotalAmount: this.forms.detail.dTotalAmount,
            dRateProtection: this.forms.detail.dRateProtection,
            iCreditPeriod: this.forms.detail.iCreditPeriod
        };

        DB.put(
            `${URI}/api/v1/${this.selectLanguage}/invoices/`,
            payload,
            {
              headers: {
                Authorization: this.$store.state.sToken
                  ? `Bearer ${this.$store.state.sToken}`
                  : ""
              }
            }
          )
        .then((response) => {
          this.loading = false;
          this.getInvoices();
          this.cancel();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    deleteInvoice() {
      this.loading = true;

      let payload = {
          sInvoiceId: this.forms.delete.sInvoiceId,
      };

      DB.put(
          `${URI}/api/v1/${this.selectLanguage}/invoices/delete`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
      .then((response) => {
        this.loading = false;
        this.getInvoices();
        this.cancel();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    editInvoice(item) {
      this.dialogEditInvoice = true;
      this.forms.detail.sInvoiceId = item.sInvoiceId;
      this.forms.detail.dPurchaseDate = item.dPurchaseDate;
      this.forms.detail.sPaymentRecipientId = item.sPaymentRecipientId;
      this.forms.detail.sInvoice = item.sInvoice;
      this.forms.detail.dTotalAmount = item.dTotalAmount;
      this.forms.detail.dRateProtection = item.dRateProtection;
      this.forms.detail.iCreditPeriod = item.iCreditPeriod;
    },
    openDialogDeleteInvoice(item) {
      this.dialogDelete = true;
      this.forms.delete.sInvoiceId = item.sInvoiceId;
    },
    convertInvoice() {
      this.loading = true;

      let payload = {
        sInvoiceId: this.forexDetail.sInvoiceId,
        sTransactionType: "e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a",
        dExchangeRate: this.forexDetail.exchangeRate,
        referenceExchangeRate: this.sExchangeRateReference,
        bExternal: false,
      };
      DB.post(
          `${URI}/api/v1/${this.selectLanguage}/transactions/createTransactionFromInvoice`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
      .then((response) => {
        this.loading = false;
        this.getInvoices();
        this.cancel();
      })
      .catch((error) => {
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    sendForward() {
      this.loading = true;

      let payload = {
        sInvoiceId: this.forwardDetail.sInvoiceId,
        sTransactionType: "b80e8dc6-4138-449e-8903-968efb8437b3",
        dExchangeRate: this.forwardRate.rate,
        referenceExchangeRate: this.forwardRate.uuid,
        bExternal: false,
        dForwardTransactionDate: this.dDateForward
      };
      DB.post(
          `${URI}/api/v1/${this.selectLanguage}/transactions/createTransactionFromInvoice`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
      .then((response) => {
        this.loading = false;
        this.getInvoices();
        this.cancel();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    payInvoice() {
      this.loading = true;

      let payload = {
        sInvoiceId: this.payDetail.sInvoiceId,
        sExchangeRateReference: this.sExchangeRateReference,
      };

      DB.post(
          `${URI}/api/v1/${this.selectLanguage}/payments/invoice`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
      .then((response) => {
        this.loading = false;
        this.getInvoices();
        this.cancel();
      })
      .catch((error) => {
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    cancel() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogSendPay = false;
      this.selectedCard = null;
      this.dialogEditInvoice = false;
      this.dialogConvert = false;
      this.dialogForex = false;
      this.dialogForward = false;
      this.forms.new = {
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
      this.forms.detail = {
        sInvoiceId: "",
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
    },
    getForwardRate() {
      this.loadingExchange = true;
      const payload = {
        dDateForward: this.dDateForward,
        dAmount: 100,
        referenceExchangeRate: this.sExchangeRateReference,
        bExternal: false
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/forwardRate`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.loadingExchange = false;
        this.forwardRate = response.data.forwardRate;
        this.forwardActualRate = response.data.forwardRate.rate;
        this.updateAmountForwardMXN();
      })
      .catch((error) => {
        this.loadingExchange = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    updateAmountForwardMXN() {
      this.forwardDetail.totalUSD = this.formatMoney(this.forwardDetail.dTotalAmount / this.forwardRate.rate);
      // this.amountForwardRealUSD = this.amountForwardMXN / this.forwardRate.rate;
    },
    getSuppliers: function () {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.suppliers = response.data.paymentRecipients
      });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    getInvoices() {
      this.isLoading = true;
      this.invoices = [];
      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.items = response.data
        this.invoices = this.items.invoices
      });
    },
    getTransactions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.transactions = response.data.transactions
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalAddFounds() {

    },
    openModalSendPay() {
      this.dialogSendPay = true;
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;

      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
            }
          } else {
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN"
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated =
              response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getInvoices();
    this.getSuppliers();
    this.getExchangeRate();
    this.today = moment().format("YYYY-MM-DD");
    this.limit = moment(this.today).add(1, 'year').format("YYYY-MM-DD");
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.selected-card {
  border: 2px solid #f0be43 !important;
}
.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
