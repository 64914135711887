<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row justify="start" align="start" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">¡Hola, {{ items.sUserName }}!</div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            Paga tus facturas y añade fondos cuando quieras.
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-auto">
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="4">
            <div class="card-content">
              <div class="card-title title-medium">Tipo de cambio actual</div>
              <div class="card-mxn h5">
                {{ formatMoney(items.exchangeRateToday ? items.exchangeRateToday : 0) }} MXN
              </div>
              <div class="card-info">
                Tipo de cambio se actualizará en: {{ formattedTime }}
              </div>
              <v-btn
                small
                color="#F0BE43"
                class="btn-custom mt-2"
                elevation="0"
                outlined
                @click="openModalSendPay"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-arrow-top-right-thin</v-icon
                >
                <span class="buttonAddFounds">Enviar pago</span>
              </v-btn>
            </div>
            <v-sheet
              class="circle-sheet"
              color="#F0BE43"
              style="border-radius: 155px"
            >
              <div class="circle-content"></div>
            </v-sheet>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">USD Balance</div>
                  <div class="content-balance">
                    ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                  </div>
                </v-col>
                <v-col cols="3" class="text-right pr-5">
                  <v-img
                    src="../../assets/images/Flags_usa.png"
                    alt="usa-flag"
                    width="50"
                    height="30"
                  />
                </v-col>
              </v-row>
              <div class="pl-3 pt-2 blocked-amount">
                Monto bloqueado forwards:
                <span class="blocked-amount-2">$0.00</span>
              </div>
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="openModalAddFoundss"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-plus-circle-outline</v-icon
                >
                <span class="buttonAddFounds">Añadir fondos</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">MXN Balance</div>
                  <div class="content-balance pt-3"> 
                    ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                  </div>
                </v-col>
                <v-col cols="3" class="text-right pr-5">
                  <v-img
                    src="../../assets/images/Flags_mex.png"
                    alt="mex-flag"
                    width="50"
                    height="30"
                  />
                </v-col>
              </v-row>
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="openDialogForex"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-swap-horizontal</v-icon
                >
                <span class="buttonAddFounds">Convertir</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-10 mx-auto" style="justify-content: space-around;">
        <v-btn
          x-large
          color="#F0BE43"
          class="btn-custom mt-4"
          elevation="0"
          style="width: 400px;"
          outlined
          @click="openModalSendPay"
        >
          <v-icon left dark color="#F0BE43"
            >mdi-arrow-top-right-thin</v-icon
          >
          <span class="buttonAddFounds">Enviar pago</span>
        </v-btn>
        <v-btn
          x-large
          color="#F0BE43"
          class="btn-custom mt-4"
          elevation="0"
          style="width: 400px;"
          outlined
          @click="openDialogForex"
        >
          <v-icon left dark color="#F0BE43"
            >mdi-swap-horizontal</v-icon
          >
          <span class="buttonAddFounds">Convertir</span>
        </v-btn>
        <v-btn
          x-large
          color="#F0BE43"
          class="btn-custom mt-4"
          elevation="0"
          style="width: 400px;"
          outlined
          @click="openDialogForward"
        >
          <v-icon left dark color="#F0BE43"
            >mdi-swap-horizontal</v-icon
          >
          <span class="buttonAddFounds">Forward</span>
        </v-btn>
      </v-row>
      <v-row class="mx-auto mt-10" style="display: flex; justify-content: space-around;">
        <v-card :to="'/invoices'" class="card-main mt-5" elevation="0" style="width: 300px; background: linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255);">
          <v-card-text class="pt-6 px-10">
            <v-row align="center" style="flex-direction: column;">
              <v-icon x-large style="color: rgb(0, 75, 80)">mdi-invoice-edit</v-icon>
              <div class="content-balance pt-3" style="font-size: 14px; color: rgb(0, 75, 80)">
                Facturas pendientes de asignar
              </div>
              <div class="content-balance pt-3" style=" color: rgb(0, 75, 80)">
                {{ items.unassignedInvoices }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card :to="'/balance'" class="card-main mt-5" elevation="0" style="width: 300px; background: linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255);">
          <v-card-text class="pt-6 px-10">
            <v-row align="center" style="flex-direction: column;">
              <v-icon x-large style="color: rgb(0, 55, 104)">mdi-cash</v-icon>
              <div class="content-balance pt-3" style="font-size: 14px; color: rgb(0, 55, 104)">
                Conversiones disponibles
              </div>
              <div class="content-balance pt-3" style=" color: rgb(0, 55, 104)">
                {{ items.transactionsAvailable }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card :to="'/forwards'" class="card-main mt-5" elevation="0" style="width: 300px; background: linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255);">
          <v-card-text class="pt-6 px-10">
            <v-row align="center" style="flex-direction: column;">
              <v-icon x-large style="color: rgb(122, 9, 22);">mdi-currency-usd</v-icon>
              <div class="content-balance pt-3" style="font-size: 14px; color: rgb(122, 9, 22);">
                Forwards activos
              </div>
              <div class="content-balance pt-3" style=" color: rgb(122, 9, 22);">
                {{ items.forwardsActive }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#F0BE43"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#f0be43">
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#f0be43">
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#f0be43">
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-card-title style="display: flex; text-align: center; justify-content: center;">
                <span class="title font-weight-bold text-textblack">
                  Selecciona el contacto al que quieres enviar el pago.
                </span>
              </v-card-title>
              <v-card-text class="py-0" >
                <div class="mb-4">
                  <v-list rounded v-if="!isLoadingSuppliers">
                    <v-subheader>Contactos</v-subheader>
                    <!-- <v-btn
                      block
                      rounded
                      outlined
                      plained
                      class="mb-4"
                    >    
                      Añadir contacto
                    </v-btn> -->
                    <v-list-item-group
                      color="#f0be43"
                      style="height: 200px; overflow: scroll; overflow-x: auto;"
                      v-model="selected"
                    >
                      <v-list-item
                        v-for="(item, i) in suppliers"
                        :key="i"
                        @click="selectSupplier(item)"
                      >
                        
                        <v-list-item-icon>
                          <v-icon v-text="'mdi-account'"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.sName"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn
                  elevation="0"
                  class="button-cancel mon-regular"
                  @click="cancel()"
                >
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="button-save"
                  style="max-width: 200px"
                  elevation="0"
                  :disabled="selected == null"
                  @click="getSendPayNow()"
                > 
                  Continuar
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title style="display: flex; text-align: center; justify-content: center;">
                  <span class="title font-weight-bold text-textblack">
                    Transferir Dinero
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0;">
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px;">
                        Cliente
                      </div>
                      <div class="" style="color: black; font-weight: bold; font-size: 22px;">
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px;">
                        Saldo de la cuenta
                      </div>
                      <div class="" style="color: black; font-weight: bold; font-size: 22px;">
                        ${{ balanceUsd }} USD
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      label="¿Cuánto quieres enviar?"
                      type="text"
                      persistent-placeholder
                      hint="Usted solo puede convertir $0.00 USD"
                      persistent-hint
                      v-model="amountForexUSD"
                      @change="updateAmountForexUSD"
                      prefix="$"
                      class="mb-5"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important;">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_usa.png"
                            alt=""
                            style="border-radius: 10px"
                          >
                          <h4 class="ml-2" style="font-weight: 500;">USD</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack" style="font-weight: 500;">
                          Monto a convertir: <span style="color: black; font-weight: bold;">${{ amountForexUSD }} USD</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          Tasa de cambio: <span style="color: black; font-weight: bold;">1 USD = ${{ exchangeRateToday }} MXN</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          Las tarifas se actualizarán en: <span style="color: black; font-weight: bold;">{{ countdownPayNow }} segundos</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      label="A Pesos Mexicanos"
                      type="text"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      @change="updateAmountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important;">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          >
                          <h4 class="ml-2" style="font-weight: 500;">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                      elevation="0"
                      class="button-cancel mon-regular"
                      @click="e1 = 1"
                    >
                      {{ texts.modalForm.cancel }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px"
                    elevation="0"
                    @click="e1 = 3"
                    >Continuar
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                  <span class="title font-weight-bold text-textblack">
                    Detalle
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
                    <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Nombre del cliente:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Número CLABE:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        {{  "********" + sTaxId }}
                      </div>
                    </div>
                    <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        ¿Cuánto quieres enviar?:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        ${{ amountForexUSD }} USD
                      </div>
                    </div>
                    <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        El destinatario recibirá:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        ${{ amountForexMXN }} MXN
                      </div>
                    </div>
                    <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Fecha de envío:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        {{ today }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                      elevation="0"
                      class="button-cancel mon-regular"
                      @click="getSendPayNow()"
                    >
                      {{ texts.modalForm.cancel }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px"
                    elevation="0"
                    color="#f0be43"
                    @click="sendPayNow()"
                    >Confirmar pago
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddFoundss" persistent width="600" class="pa-8">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="title font-weight-bold text-textblack">
            Añadir fondos
          </span>
          <v-btn icon @click="dialogAddFoundss = false">
            <v-icon color="#2E404E">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-6">
          <v-sheet rounded="lg" color="#F7F8F9" class="pa-13">
            <div class="mb-4">
              <div class="subtitle-1 font-weight-bold text-textblack">
                Detalle
              </div>
              <v-divider></v-divider>
            </div>

            <div class="my-2 pl-3 pr-8">
              <v-row
                v-for="(value, key) in dataAddFounds"
                :key="key"
                align="center"
                justify="space-between"
                class="mb-4"
              >
                <div class="body-1 modalTextAddFounds">{{ key }}</div>
                <div class="subtitle-2 font-weight-bold text-textblack">
                  {{ value }}
                </div>
              </v-row>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>  
    <v-dialog v-model="dialogForex" persistent width="550" class="pa-8">
      <v-card>
        <v-card-title style="display: flex; text-align: center; justify-content: center;">
          <span class="title font-weight-bold text-textblack">
            Convertir
          </span>
        </v-card-title>
        <v-btn icon @click="cancel()" style="margin-right: 10px; position: absolute; right: 0; top: 0; margin-top: 10px;">
          <v-icon color="#2E404E">mdi-close</v-icon>
        </v-btn>
        <v-card-text class="py-6">
          <div class="mb-4">
            <div class="subtitle-1 font-weight-bold text-textblack mb-8" style="color: black;">
              Convierte la cantidad que quieras a una moneda diferente.
            </div>
            <div v-if="!loadingExchange">
              <v-text-field
                outlined
                label="De USD Dolares"
                type="text"
                :disabled="loadingExchange"
                persistent-placeholder
                style="font-weight: bold;"
                :hint="customHint"
                v-model="amountForexUSD"
                @change="updateAmountForexUSD"
                persistent-hint
                prefix="$"
                class="mb-10"
              >
                <template v-slot:append>
                  <div style="display: flex; align-items: center !important;">
                    <img
                      width="24"
                      height="24"
                      src="../../assets/images/Flags_usa.png"
                      alt=""
                      style="border-radius: 10px"
                    >
                    <h4 class="ml-2" style="font-weight: 500;">USD</h4>
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                :disabled="loadingExchange"
                label="A Pesos Mexicanos"
                type="text"
                style="font-weight: bold;"
                v-model="amountForexMXN"
                @change="updateAmountForexMXN"
                persistent-placeholder
                prefix="$"
              >
                <template v-slot:append>
                  <div style="display: flex; align-items: center !important;">
                    <img
                      width="24"
                      height="24"
                      src="../../assets/images/Flags_mex.png"
                      alt=""
                      style="border-radius: 10px"
                    >
                    <h4 class="ml-2" style="font-weight: 500;">MXN</h4>
                  </div>
                </template>
              </v-text-field>
              <v-row>
                <v-col class="pb-0">
                  <div class="subtitle-1 font-weight-bold text-textblack">
                    Tasa de cambio
                  </div>
                </v-col>
                <v-col class="pb-0" style="display: flex; justify-content: flex-end;">
                  <h2 style="color: black;">
                    1 USD = ${{ exchangeRateToday }} MXN
                  </h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div class="subtitle-1 font-weight-bold text-textblack">
                    Las tarifas se actualizarán en
                  </div>
                </v-col>
                <v-col class="pb-0" style="display: flex; justify-content: flex-end;">
                  <h2 style="color: black;">
                    {{ countdown }} segundos
                  </h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div class="subtitle-1 font-weight-bold text-textblack">
                    Total convertido
                  </div>
                </v-col>
                <v-col class="pb-0" style="display: flex; justify-content: flex-end;">
                  <h2 style="color: black;">
                    ${{ amountForexMXN ? amountForexMXN : 0 }} MXN
                  </h2>
                </v-col>
              </v-row>
              <div class="mt-10" style="display: flex; justify-content: center;">
                <v-btn
                  large
                  color="#F0BE43"
                  class="btn-custom mt-2"
                  elevation="0"
                  style="width: 400px;"
                  outlined
                  @click="sendForex"
                >
                  <span class="buttonAddFounds">Realizar conversión</span>
                </v-btn>
              </div>
            </div>
            <v-skeleton-loader
              v-else
              class="mx-auto"
              type="image"
            ></v-skeleton-loader>
          </div>
        </v-card-text> 
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogForward" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#F0BE43"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#f0be43">
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#f0be43">
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-card-title style="display: flex; text-align: center; justify-content: center;">
                <span class="title font-weight-bold text-textblack">
                  Comprar un forward
                </span>
              </v-card-title>
              <v-card-text class="py-6">
                <div class="mb-4">
                  <div class="subtitle-1 font-weight-bold text-textblack mb-8" style="color: black;">
                    Convierte la cantidad que quieras a una moneda diferente.
                  </div>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="dDateForward"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dDateForward"
                        label="Selecciona la fecha de tu forward"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        class="mb-3"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dDateForward"
                      :min="today"
                      :max="limit"
                      @change="getForwardRate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        :disabled="loadingExchange"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        :disabled="loadingExchange"
                        @click="$refs.dialog.save(dDateForward)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field
                    outlined
                    :disabled="dDateForward == null"
                    label="De USD Dolares"
                    type="text"
                    persistent-placeholder
                    v-model="amountForwardUSD"
                    @change="updateAmountForwardUSD"
                    :hint="customHint"
                    persistent-hint
                    prefix="$"
                    class="mb-10"
                  >
                    <template v-slot:append>
                      <div style="display: flex; align-items: center !important;">
                        <img
                          width="24"
                          height="24"
                          src="../../assets/images/Flags_usa.png"
                          alt=""
                          style="border-radius: 10px"
                        >
                        <h4 class="ml-2" style="font-weight: 500;">USD</h4>
                      </div>
                    </template>
                  </v-text-field>
                  <v-text-field
                    outlined
                    label="A Pesos Mexicanos"
                    :disabled="dDateForward == null"
                    v-model="amountForwardMXN"
                    @change="updateAmountForwardMXN"
                    type="text"
                    persistent-placeholder
                    prefix="$"
                  >
                    <template v-slot:append>
                      <div style="display: flex; align-items: center !important;">
                        <img
                          width="24"
                          height="24"
                          src="../../assets/images/Flags_mex.png"
                          alt=""
                          style="border-radius: 10px"
                        >
                        <h4 class="ml-2" style="font-weight: 500;">MXN</h4>
                      </div>
                    </template>
                  </v-text-field>
                  <div v-if="!loadingExchange">
                    <v-row v-if="forwardRate">
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          Tasa de cambio:
                        </div>
                      </v-col>
                      <v-col class="pb-0" style="display: flex; justify-content: flex-end;">
                        <h2 style="color: black;">
                          1 USD = ${{ formatMoney(forwardRate.rate) }} MXN
                        </h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          Total convertido:
                        </div>
                      </v-col>
                      <v-col class="pb-0" style="display: flex; justify-content: flex-end;">
                        <h2 style="color: black;">
                          ${{ amountForwardMXN }} MXN
                        </h2>
                      </v-col>
                    </v-row>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn
                    elevation="0"
                    class="button-cancel mon-regular"
                    @click="cancel()"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                <v-btn
                  class="button-save"
                  style="max-width: 200px"
                  elevation="0"
                  :disabled="dDateForward == null"
                  @click="e1 = 2"
                  >Continuar
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                  <span class="title font-weight-bold text-textblack">
                    Detalle
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="forwardRate">
                  <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
                    <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Tasa de cambio:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        1 USD = ${{ formatMoney(forwardRate.rate) }} MXN
                      </div>
                    </div>
                    <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        ¿Cuánto quieres enviar?:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        ${{ amountForwardUSD }} USD
                      </div>
                    </div>
                    <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Monto a recibir en tu cuenta:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        ${{ amountForwardMXN }} MXN
                      </div>
                    </div>
                    <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                      <div class="" style="color: black; font-size: 16px;">
                        Fecha programada:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                        {{ dDateForward }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                      elevation="0"
                      class="button-cancel mon-regular"
                      @click="e1 = 1"
                    >
                      {{ texts.modalForm.cancel }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px"
                    elevation="0"
                    color="#f0be43"
                    @click="sendForward"
                    >Programar pago
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-overlay style="z-index: 99999999;" :value="loading">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      clockTime: "",
      selected: null,
      lastUpdateclockTime: "",
      texts: "",
      amountForexUSD: 0,
      amountForexMXN: 0,
      amountForwardUSD: 0,
      amountForwardMXN: 0,
      amountForexRealUSD: 0,
      amountForwardRealUSD: 0,
      dialogForward: false,
      loading: true,
      dialogForex: false,
      modal: false,
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      selectedItem: null,
      itemss: [
        { text: 'SFD Produce', icon: 'mdi-account' },
        { text: 'Vikam', icon: 'mdi-account' },
        { text: 'Perlag', icon: 'mdi-account' },
        { text: 'PROPLAT LLC Wallet', icon: 'mdi-account' },
        { text: 'Jorge Florido', icon: 'mdi-account' },
      ],
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balance: "0",
      items: [],
      today: null,
      tab: 0,
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      dDateForward: null,
      menu: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      headersInvoices: [
        { text: "Supplier", value: "sProvider", align: "center" },
        { text: "Purchase date", value: "dPurchaseDate", align: "center" },
        { text: "Invoice", value: "sInvoice", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Exchange rate protection MXN", value: "dRateProtection", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Status", value: "sStatusId", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForwards: [
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Transaction date", value: "dTransactionDate", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Missing days", value: "diasFaltantes", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate", value: "dRateProtection", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForex: [
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate", value: "dRateProtection", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      itemsForwards: [
        { type: "Forward", dTransactionDate: "March 15, 2024", diasFaltantes: "-7 days", dDueDate: "April 15, 2024", dTotalAmount: "300000", dRateProtection: "16.50", invoice: "", purchaseDate: "", dProfitPercentage: "", dCurrencyGain: "" },
        { type: "Forward", dTransactionDate: "April 1, 2024", diasFaltantes: "8 days", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-102", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "400"}
      ],
      itemsForex: [
        { type: "Forex", diasFaltantes: "-3", dDueDate: "April 15, 2024", dTotalAmount: "300000", dRateProtection: "16.50", invoice: "", purchaseDate: "", dProfitPercentage: "", dCurrencyGain: "" },
        { type: "Forex", diasFaltantes: "13", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-103", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "3000"},
        { type: "Forward", diasFaltantes: "13", dDueDate: "April 30, 2024", dTotalAmount: "100000", dRateProtection: "16.50", invoice: "D-104", purchaseDate: "April 1, 2024", dProfitPercentage: "4.5", dCurrencyGain: "200"}
      ],
      timerId: null,
      dataEfex: {},
      balanceUsd: null,
      balanceMxn: null,
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false
            },
          ]
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true
            }
          ]
        }
      ],
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" }
      ],
      transactions: [],
      invoices: [],
      exchangeRateToday: null,
      exchangeRateTodayFullNumbers: null,
      exchangeRateTodayReference: null,
      loadingExchange: false,
      countdown: 59,
      countdownPayNow: 59,
      intervalId: null,
      isLoadingSuppliers: false,
      forwardRate: null,
      suppliers: [],
      supplier: {},
      sTaxId: null,
      limit: null,
    };    
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    startCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdown = 59;
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
          this.updateDialogForex();
        }
      }, 1000);
    },
    updateAmountForexUSD() {
      this.amountForexMXN = this.formatMoney((this.amountForexUSD * this.exchangeRateTodayFullNumbers));
      this.amountForexRealUSD = this.amountForexUSD
    },
    updateAmountForexMXN() {
      this.amountForexUSD = this.formatMoney((this.amountForexMXN / this.exchangeRateTodayFullNumbers));
      this.amountForexRealUSD = this.amountForexMXN / this.exchangeRateTodayFullNumbers;
    },
    updateAmountForwardUSD() {
      this.amountForwardMXN = this.formatMoney(this.amountForwardUSD * this.forwardRate.rate);
      this.amountForwardRealUSD = this.amountForwardUSD;
    },
    updateAmountForwardMXN() {
      this.amountForwardUSD = this.formatMoney(this.amountForwardMXN / this.forwardRate.rate);
      this.amountForwardRealUSD = this.amountForwardMXN / this.forwardRate.rate;
    },
    sendForex() {
      this.loading = true;
      const payload = {
        sTransactionType: "e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a",
        dAmount: parseFloat(this.amountForexRealUSD),
        dExchangeRate: this.exchangeRateToday,
        referenceExchangeRate: this.exchangeRateTodayReference,
        bExternal: false
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.cancel();
        this.getDashboard();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    sendForward() {
      this.loading = true;
      const payload = {
        sTransactionType: "b80e8dc6-4138-449e-8903-968efb8437b3",
        dAmount: parseFloat(this.amountForwardRealUSD),
        dExchangeRate: this.forwardRate.rate,
        referenceExchangeRate: this.forwardRate.uuid,
        bExternal: false,
        dForwardTransactionDate: this.dDateForward
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.cancel();
        this.getDashboard();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    getForwardRate() {
      this.loadingExchange = true;
      const payload = {
        dDateForward: this.dDateForward,
        dAmount: 100,
        referenceExchangeRate: this.exchangeRateTodayReference,
        bExternal: false
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/forwardRate`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.loadingExchange = false;
        this.forwardRate = response.data.forwardRate;
        this.updateAmountForwardMXN();
      })
      .catch((error) => {
        this.loadingExchange = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    cancel() {
      this.loading = false;
      this.dialogForex = false;
      this.dialogSendPay = false;
      this.dialogForward = false;
      this.exchangeRateToday = 0;
      this.amountForexUSD = 0;
      this.amountForexMXN = 0;
      this.forwardRate = null;
      this.amountForwardUSD = 0;
      this.amountForwardMXN = 0;
      this.dDateForward = null;
      this.suppliers = [];
      this.selected = null;
    },
    getDashboard() {
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.loading = false;
        this.items = response.data;
        this.balanceUsd = response.data.balances.balanceUsd;
        this.balanceMxn = response.data.balances.balanceMxn;
        this.exchangeRateTodayReference = this.items.exchangeRateTodayReference;
      });
    },
    updateDialogForex() {
      this.startCountdown();
      this.getExchangeRate();
    },
    openDialogForex() {
      this.dialogForex = true
      this.startCountdown();
      this.getExchangeRate();
    },
    getExchangeRate() {
      this.loadingExchange = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.loadingExchange = false;
        this.exchangeRateTodayFullNumbers = response.data.exchangeRateToday;
        this.exchangeRateToday = Math.round(response.data.exchangeRateToday * 100) / 100;;
        this.exchangeRateTodayReference = response.data.exchangeRateTodayReference;
      });
    },
    getInvoices() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/?exchangeRateToday=${this.actualCurrency}`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    getTransactions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.transactions = response.data.transactions
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openDialogForward() {
      this.dialogForward = true;
      this.dDateForward = null;
      this.e1 = 1;
    },
    openModalSendPay() {
      this.e1 = 1;
      this.dialogSendPay = true;
      this.getSuppliers();
    },
    getSuppliers() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.isLoadingSuppliers = false;
        this.suppliers = response.data.paymentRecipients
      })
      .catch((error) => {
        this.isLoadingSuppliers = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    selectSupplier(item) {
      this.supplier = item;
      this.sTaxId = this.supplier.sTaxId.slice(-4);
    },
    getSendPayNow() {
      this.e1 = 2;
      this.getExchangeRate();
      this.amountForexMXN = 0;
      this.amountForexRealUSD = 0;
      this.amountForexUSD = 0;
      this.startCountdownToPayNow();
    },
    sendPayNow() {
      this.loading = true;

      const payload = {
        dAmount: parseFloat(this.amountForexRealUSD),
        comment: "Prueba",
        sPaymentRecipientId: this.supplier.sPaymentRecipientId,
        sExchangeRateReference: this.exchangeRateTodayReference,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/payments/sendPayNow`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.cancel();
        this.getDashboard();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    startCountdownToPayNow() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdownPayNow = 59;
      this.intervalId = setInterval(() => {
        if (this.countdownPayNow > 0) {
          this.countdownPayNow--;
        } else {
          clearInterval(this.intervalId);
          if (this.e1 == 2) {
            this.getSendPayNow(); 
          }
        }
      }, 1000);
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;
      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
            }
          } else {
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };


      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN"
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated =
              response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getDashboard();
    this.today = moment().format("YYYY-MM-DD");
    this.limit = moment(this.today).add(1, 'year').format("YYYY-MM-DD");
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    customHint() {  // Combina texto fijo con variables
      return `Usted solo puede convertir $${this.items ? this.balanceUsd : 0} USD`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
    clockTime(currentTime) {
      var ms = moment(this.lastUpdateclockTime, "HH:mm:ss").diff(moment(currentTime, "HH:mm:ss"));
      var d = moment.duration(ms);
    },
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
