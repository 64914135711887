<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row class="ma-0 mb-5">
        <div class="ma-4">
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">Saldo disponible MXN</div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">
              Aqui encontraras tus conversiones disponibles para asignar facturas o ejecutar pagos.
            </div>
          </v-col>
        </div>
        <v-spacer></v-spacer>
        <v-card class="card-main" elevation="0" outlined>
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">MXN Balance</div>
                <div class="content-balance">
                  ${{ formatMoney(balanceMxn) }} MXN
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  src="../../assets/images/Flags_mex.png"
                  alt="mex-flag"
                  width="50"
                  height="30"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
      <v-card class="rounded-xl elevation-5">
        <v-toolbar dense dark color="#1d2b3c" elevation="2">
          <v-toolbar-title>Conversiones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn elevation="0" text small>
          </v-btn>
        </v-toolbar> <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            style="
              text-align: center;
              color: #f0be43;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #f0be43;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            :disabled="selected.length == 0"
            @click="openPayNow(selected)"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            Pagar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-spacer></v-spacer>
          <v-data-table show-select single-select item-key="sTransactionId" v-model="selected" :headers="headersForex" :items="items" :disable-sort="true">
            <template v-slot:item.sTransactionType="{ item }">
              <v-chip
                v-if="item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
                class="ma-2"
                color="red"
                outlined
              >
                {{ 'Forward' }}
              </v-chip>
              <v-chip
                v-else-if="item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'"
                class="ma-2"
                color="blue"
                outlined
              >
                {{ 'Forex' }}
              </v-chip>
            </template>
            <template v-slot:item.dAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dAmount) }} USD </h4>
            </template>
            <template v-slot:item.dDesAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dDesAmount) }} MXN </h4>
            </template>
            <template v-slot:item.dExchangeRate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dExchangeRate) }} MXN </h4>
            </template>
            <template v-slot:item.invoice="{ item }">
              <div v-if="item.invoice.length > 0">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;" v-for="(item, idx) in item.invoice"> {{ item.invoice.sInvoice }}</h4>
              </div>
              <div v-else>
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;">{{ "N/A" }}</h4>
              </div>
            </template>
            <template v-slot:item.created_at="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;">{{ formatDate(item.created_at) }}</h4>
            </template>
            <template v-slot:item.purchaseDate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoice.length > 0 ? formatDate(item.invoice[0].invoice.dPurchaseDate) : "N/A" }}  </h4>
            </template>
            <template v-slot:item.percentProfit="{ item }">
              <h4 v-if="item.percentProfit" :style="{ color: item.percentProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.percentProfit ? (formatMoney(item.percentProfit) + "%") : "N/A" }} </h4>
              <h4 v-else> {{ "N/A" }}</h4>
            </template>
            <template v-slot:item.dollarProfit="{ item }">
              <h4 v-if="item.dollarProfit" :style="{ color: item.dollarProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dollarProfit ? ('$ ' + formatMoney(item.dollarProfit) + ' USD') : "" }}</h4>
              <h4 v-else> {{ "N/A" }}</h4>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="display: flex; align-items: center; justify-content: center;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openDialog(item)" v-bind="attrs" v-on="on" icon color="black">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar conversión</span>
                </v-tooltip>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete item</span>
                </v-tooltip> -->
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-container class="mt-1">
        <div v-if="step == 1"> 
          <v-card-title style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Selecciona el contacto al que quieres enviar el pago.
            </span>
          </v-card-title>
          <v-card-text class="py-0" >
            <div class="mb-4">
              <v-list rounded v-if="!isLoadingSuppliers">
                <v-subheader style="font-size: 24px; color: black">Contactos</v-subheader>
                <v-list-item-group
                  color="#f0be43"
                  style="height: 300px; overflow: scroll; overflow-x: auto;"
                  v-model="selectedSupplier"
                >
                  <v-list-item
                    style="border: 1px solid grey;"
                    class="mb-4"
                    v-for="(item, i) in suppliers"
                    :key="i"

                    @click="selectSupplier(item)"
                  >
                    
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-account'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.sName"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </div>
          </v-card-text>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                @click="cancel()"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              @click="step = 2"
              >Continuar
            </v-btn>
          </div>
        </div>
        <div v-if="step == 2">
          <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
            <span class="title font-weight-bold text-textblack">
              Detalle de pago
            </span>
          </v-card-title>
          <v-card-text class="py-6 pb-0" v-if="!loading">
            <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px">
              <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Nombre del cliente:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ conversionPayment.detail.supplier.sName }}
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Número CLABE:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{  "********" + sTaxId }}
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Cantidad a enviar:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ conversionPayment.detail.conversion[0].dAmount }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  El destinatario recibirá:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ conversionPayment.detail.conversion[0].dDesAmount }} MXN
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Tipo de cambio:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  ${{ conversionPayment.detail.conversion[0].dExchangeRate }} USD
                </div>
              </div>
              <div class="py-4 px-10" style="display: flex; justify-content: space-between;" v-if="conversionPayment.detail.conversion[0].invoice.length > 0">
                <div class="" style="color: black; font-size: 16px;">
                  Factura a pagar:
                </div>
                
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ conversionPayment.detail.conversion[0].invoice[0].invoice.sInvoice }}
                </div>
              </div>
              <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                <div class="" style="color: black; font-size: 16px;">
                  Fecha de envío:
                </div>
                <div class="" style="color: black; font-size: 22px; font-weight: bold;">
                  {{ today }}
                </div>
              </div>
            </div>
          </v-card-text>
          <div v-else style="display: flex; justify-content: center;">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                @click="cancel()"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              @click="sendPayment()"
              >Confirmar pago
            </v-btn>
          </div>
        </div> 
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetailConversion" max-width="1000">
      <v-card>
        <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
          <h3 class="font-weight-bold my-4">
            Detalle de {{ "conversión" }}
          </h3>
        </v-card-title>
        <v-card-text class="pb-5 px-10">
          <div class="my-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                Tipo
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ conversion.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a' ? "Forex" : "Forward" }}
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px; text-align: center;">
                  Monto
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ "$ " + formatMoney(conversion.dDesAmount) + " MXN" }}
                </div>
              </div>
              <template v-if="conversion.invoice">
                <div v-if="conversion.invoice.length > 0">
                  <div style="color: black; font-size: 20px;">
                    Profit
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.percentProfit) + "%"  }}
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.dollarProfit) + " USD"  }}
                  </div>
                </div>
                <div v-else></div>
              </template>
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;" v-if="conversion.invoice">
                Provider: <span style="color: black; font-size: 22px; font-weight: bold;" v-if="conversion.invoice.length > 0"> {{ conversion.invoice[0].invoice.paymentsRecipent[0].sName }}</span>
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px;">
                Exchange rate: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ "$ " + conversion.dExchangeRate + " MXN" }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                Fecha de creación: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.created_at) }}</span>
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px;" v-if="conversion.dForwardTransactionDate">
                  Fecha de cumplimiento: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.dForwardTransactionDate) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold;">Porcentaje de la conversion utilizado</div>
            <v-progress-linear
              height="45"
            >
              <strong>{{ ((conversion.totalAmount * 100) / parseFloat(conversion.dDesAmount)) }}%</strong>
            </v-progress-linear>
          </div>
          <div>
            <div class="mb-6" style="display: flex; justify-content: space-around; color: black; font-size: 22px; font-weight: bold;"> 
              <div>Invoices asignadas</div>
              <v-spacer></v-spacer>
              <v-btn
                style="
                  text-align: center;
                  color: #f0be43;
                  font-size: 15px;
                  background-color: transparent;
                  font-family: montserrat-bold;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  word-wrap: break-word;
                  border-radius: 5px;
                  border: 1px solid #f0be43;
                  padding: 8px 16px;
                "
                elevation="0"
                @click="updateInvoice()"
                class="ml-2"
                :disabled="((conversion.totalAmount * 100) / parseFloat(conversion.dDesAmount)) == 100"
              >
                <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
                Editar facturas asignadas
              </v-btn>
            </div>
            <div v-if="conversion.invoice">
              <v-card :disabled="active" class="mb-4" v-for="(item, idx) in conversion.invoice">
                <v-card-title>
                  <h3>Invoice: {{ item.invoice.sInvoice }}</h3>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="true" v-bind="attrs" v-on="on" icon color="black">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar factura</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="deleteInvoice(item)" v-bind="attrs" v-on="on" icon color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar factura de la conversión</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div class="mt-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <div>
                        <div style="color: black; font-size: 20px;">
                          Monto
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.dAmount) + "MXN" }}
                        </div>
                      </div>
                      <div>
                        <div style="color: black; font-size: 20px;">
                        Fecha de compra
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ formatDate(item.invoice.dPurchaseDate) }}
                        </div>
                      </div>
                      <div>
                        <div style="color: black; font-size: 20px; text-align: center;">
                          Exchange rate protected
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.invoice.dRateProtection) + " MXN" }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-6" style="display: flex; justify-content: space-between;">
                      <div>
                        <div class="mb-2" style="color: black; font-size: 20px;">
                          Profit (%)
                        </div>
                        <div :style="{ color: item.invoice.dProfitPercentag < 0 ? 'red' : 'green'}" style="color: black; font-size: 22px; font-weight: bold;">
                          {{ item.invoice.dProfitPercentage + "%" }}
                        </div>
                      </div>
                      <div>
                        <div class="mb-2" style="color: black; font-size: 20px;">
                        Profit
                        </div>
                        <div :style="{ color: item.invoice.dProfitPercentag < 0 ? 'red' : 'green'}"style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + item.invoice.dCurrencyGain + " USD" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div v-if="!loadingTransaction">
              <v-container v-if="active">
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      label="Nombre del proveedor*"
                      required
                      :items="suppliers"
                      item-value="sPaymentRecipientId"
                      item-text="sName"
                      @change="getInvoicesWithSupplier(sPaymentRecipientId)"
                      v-model="sPaymentRecipientId"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-card class="mb-4" v-for="(item,idx) in avaibleInvoices">
                  <v-card-title>
                    <h3>Invoice: {{ item.sInvoice }}</h3>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="text-align: center; color: #f0be43; font-size: 15px; background-color: transparent; font-family: montserrat-bold; font-weight: 600; line-height: 24px; text-transform: capitalize; word-wrap: break-word; border-radius: 5px; border: 1px solid #f0be43; padding: 8px 16px;"
                          elevation="0"
                          @click="addInvoice(item)"
                          class="ml-2"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Agregar
                        </v-btn>
                      </template>
                      <span>Agregar factura a la invoice</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <div class="mt-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div>
                          <div style="color: black; font-size: 20px;">
                            Monto
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.dTotalAmount) + "MXN" }}
                          </div>
                        </div>
                        <div>
                          <div style="color: black; font-size: 20px;">
                          Fecha de compra
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ formatDate(item.dPurchaseDate) }}
                          </div>
                        </div>
                        <div>
                          <div style="color: black; font-size: 20px; text-align: center;">
                            Exchange rate protected
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.dRateProtection) + " MXN" }}
                          </div>
                        </div>
                      </div>
                      <div class="mt-6" style="display: flex; justify-content: space-between;">
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                            Profit (%)
                          </div>
                          <div :style="{ color: 1 < 0 ? 'red' : 'green'}" style="color: black; font-size: 22px; font-weight: bold;">
                            {{ formatMoney(item.dProfitPercentage) + "%" }}
                          </div>
                        </div>
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                          Profit
                          </div>
                          <div :style="{ color: 1 < 0 ? 'red' : 'green'}"style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.dCurrencyGain) + " USD" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-container>
            </div>
            <v-skeleton-loader
              v-else
              class="mx-auto"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
                elevation="0"
                class="button-cancel mon-regular"
                @click="cancel()"
              >
                {{ texts.modalForm.cancel }}
              </v-btn>
              <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px"
              elevation="0"
              color="#f0be43"
              :disabled="true"
              @click=""
              >Confirmar pago
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      drawer: null,
      dialogSendPay: false,
      dialogDetailConversion: false,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      selected: [],
      selectedSupplier: null,
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      active: false,
      tab: 0,
      contacts: [],
      step: 0,
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      today: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      suppliers: [],
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      headersForex: [
        { text: "Fecha de creación", value: "created_at", align: "center" },
        { text: "Type Transaction", value: "sTransactionType", align: "center" },
        { text: "Total amount USD", value: "dAmount", align: "center" },
        { text: "Total amount MXN", value: "dDesAmount", align: "center" },
        { text: "Exchange rate", value: "dExchangeRate", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "percentProfit", align: "center" },
        { text: "Currency gain", value: "dollarProfit", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      timerId: null,
      dataEfex: {},
      avaibleInvoices: [],
      loading: false,
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      loadingTransaction: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false
            },
          ]
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true
            }
          ]
        }
      ],
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" }
      ],
      transactions: [],
      conversion: {},
      invoices: [],
      supplier: {},
      sTaxId: null,
      sPaymentRecipientId: "",
      conversionPayment: {
        sTransactionId: "",
        sPaymentRecipientId: "",
        dAmount: "",
        detail: {
          supplier: {},
          conversion: {}
        },
      }
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    updateInvoice() {
      this.active = true;
    },
    openDialog(item) {
      this.dialogDetailConversion = true;
      this.getConversionsWithId(item)
    },
    addInvoice(item) {
      this.loadingTransaction = true;
      this.avaibleInvoices = [];

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount
          }
        ]
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/invoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);
          this.active = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    deleteInvoice(item) {
      this.loadingTransaction = true;

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount
          }
        ]
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/deleteInvoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);
          
          this.active = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSupplier(item) {
      this.conversionPayment.detail.supplier = item
      this.conversionPayment.detail.sPaymentRecipientId = item.sPaymentRecipientId
      this.sTaxId = this.conversionPayment.detail.supplier.sTaxId.slice(-4);
    },
    openPayNow(item) {
      console.log(item)
      this.conversionPayment.detail.sTransactionId = item[0].sTransactionId
      this.conversionPayment.detail.dAmount = item[0].dDesAmount
      this.conversionPayment.detail.conversion = item
      if (item[0].invoice.length == 0) {
        this.dialogSendPay = true;
        this.step = 1;
      } else {
        this.dialogSendPay = true;
        this.conversionPayment.detail.supplier = this.suppliers.find(supplier => supplier.sPaymentRecipientId === item[0].invoice[0].invoice.sPaymentRecipientId);
        console.log(this.conversionPayment.detail.supplier)
        this.conversionPayment.detail.sPaymentRecipientId = item[0].invoice[0].invoice.sPaymentRecipientId
        this.sTaxId = this.conversionPayment.detail.supplier.sTaxId.slice(-4);
        this.step = 2;
      }
    },
    sendPayment() {
      this.loading = true;
      const payload = {
        sTransactionId: this.conversionPayment.detail.sTransactionId,
        sPaymentRecipientId: this.conversionPayment.detail.sPaymentRecipientId,
        isMxn: true,
        dAmount: this.conversionPayment.detail.dAmount,
        comment: "Pago"
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/payments/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.cancel();
        this.getConversions();
        this.getSuppliers();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    getInvoicesWithSupplier(item){
      this.loadingTransaction = true;
      this.avaibleInvoices = [];

      const payload = {
        sPaymentRecipientId: item,
        dExchangeRate: this.conversion.dExchangeRate
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/invoices/supplier`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.avaibleInvoices = response.data.invoices
          this.loadingTransaction = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    cancel() {
      this.dialogSendPay = false;
      this.step = 0;
      this.avaibleInvoices = [];
      this.selected = [];
      this.dialogDetailConversion = false;
      this.active = false;
      this.selectedSupplier = [];
    },
    getConversionsWithId(item) {
      this.loadingTransaction = true;
      this.conversion = [];

      const payload = {
        sTransactionId: item.sTransactionId,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/id`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.conversionWithId = response.data.transactions.map(transaction => {
            let totalAmount = 0;
            if (transaction.invoice.length > 0) {
              transaction.invoice.map(invoice => {
                totalAmount += parseFloat(invoice.dAmount);
              });
            }
            transaction.totalAmount = totalAmount;
            return transaction;
          });
          
          this.conversion = this.conversionWithId[0];
          this.getConversions();
          this.loadingTransaction = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getConversions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.items = response.data.transactions.map(transaction => {
          let totalAmount = 0;
          if (transaction.invoice.length > 0) {
            transaction.invoice.map(invoice => {
              totalAmount += parseFloat(invoice.dAmount);
            });
            
          }
          transaction.totalAmount = totalAmount;
          return transaction;
        });
        this.items.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);

          return dateB - dateA;
        });
        this.balanceMxn = response.data.balance.balanceMxn;
      });
    },
    getInvoices() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/?exchangeRateToday=${this.actualCurrency}`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.invoices = response.data.invoices;
        console.log(this.invoices)
      });
    },
    getTransactions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.transactions = response.data.transactions
        console.log(this.transactions)
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    getExchangeRate: function () {
      this.isLoading = true;
      this.items = [];

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken ? `Bearer ${this.$store.state.sToken}` : ""
          }
        }
      )
        .then((response) => {
          this.isForwardsActive = response.data.isForwardsActive;
          this.userName = response.data.user;
          this.isRegisterEfex = response.data.registerInEfex;
          this.exchangeRateDataPaid =
            response.data.exchangeRate.exchangeRatesStatePaid;
          this.exchangeRateData =
            response.data.exchangeRate.exchangeRatesStateToPay;
          this.exchangeRateDataSeparate =
            response.data.exchangeRate.exchangeRatesStateSeparated;
          this.exchangeRatesStateForwards =
            response.data.exchangeRate.exchangeRatesStateForwards;
          this.balanceMxn = response.data.balance.balanceMxn;
          this.balanceUsd = response.data.balance.balanceUsd;
          this.exchangeRateAdjustments = response.data.exchangeRateAdjustments;
          this.balance =
            parseFloat(this.balanceMxn) / parseFloat(this.actualCurrency) +
            parseFloat(this.balanceUsd);
          this.balance = this.balance.toString();

          // this.totalUsd = 0;

          // this.itemsSelected.forEach((item) => {
          //   item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          //   this.totalUsd += item.dTotalUsdToday;
          // });

          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.isLoading = false;
          this.getContacts();
        });
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalSendPay() {
      this.dialogSendPay = true;
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;

      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dDesAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      console.log("sendToPayNowForwards");
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd
      };

      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          console.log("response", response);
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
              console.error("Error al decodificar base64: ", e);
            }
          } else {
            console.error(
              "No se encontraron los datos del PDF en la respuesta"
            );
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };

      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        console.log("item", item);
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN"
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated =
              response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    },
    getSuppliers() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.isLoadingSuppliers = false;
        this.suppliers = response.data.paymentRecipients
      })
      .catch((error) => {
        this.isLoadingSuppliers = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getConversions();
    this.getSuppliers();
    this.today = moment().format("YYYY-MM-DD");
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: fit-content;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #1d2b3c;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
